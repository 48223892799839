import React from 'react';
import Loadable from 'react-loadable';
import ReactLoading from 'react-loading';

function Loading() {
	return (
		<div className="col-xs-12 text-center m-t-3">
			<ReactLoading type="spin" color="#ccc" height={'6%'} width={'6%'} className="center-block m-b-1" />
			<span className="m-t-1 precios-bajada">Cargando...</span>
		</div>
	);
}

const PageHome = Loadable({
	loader: () => import('../pages/AppSteps'),
	loading: function() {
		console.log('Loading step home');
		return (
			<div className="col-xs-12 text-center m-t-3">
				<ReactLoading type="spin" color="#ccc" height={'6%'} width={'6%'} className="center-block m-b-1" />
				<span className="m-t-1 precios-bajada">Cargando...</span>
			</div>
		);
	}
});

const PagePromociones = Loadable({
	loader: () => import('../pages/Promociones'),
	loading: Loading
});

const SobrePagina = Loadable({
	loader: () => import('../pages/SobrePagina'),
	loading: Loading
});

const Step2 = Loadable({
	loader: () => import('../pages/AppSteps'),
	loading: function() {
		console.log('Loading step2');
		return (
			<div className="col-xs-12 text-center m-t-3">
				<ReactLoading type="spin" color="#ccc" height={'6%'} width={'6%'} className="center-block m-b-1" />
				<span className="m-t-1 precios-bajada">Cargando...</span>
			</div>
		);
	}
});

const Step3 = Loadable({
	loader: () => import('../pages/AppSteps'),
	loading: function() {
		return (
			<div className="col-xs-12 text-center m-t-3">
				<ReactLoading type="spin" color="#ccc" height={'6%'} width={'6%'} className="center-block m-b-1" />
				<span className="m-t-1 precios-bajada">Cargando...</span>
			</div>
		);
	}
});

const FromArgentina = Loadable({
	loader: () => import('../pages/AppSteps'),
	loading: function() {
		window.sessionStorage.setItem('step', 1);
		window.sessionStorage.setItem('modal', false);
		window.sessionStorage.removeItem('coords');
		return (
			<div className="col-xs-12 text-center m-t-3">
				<ReactLoading type="spin" color="#ccc" height={'6%'} width={'6%'} className="center-block m-b-1" />
				<span className="m-t-1 precios-bajada">Cargando...</span>
			</div>
		);
	}
});

const NotFound = Loadable({
	loader: () => import('../pages/NotFound'),
	loading: Loading
});
const routes = [
	{ path: '/', name: 'Precios', component: PageHome, exact: true },
	{ path: '/promociones', name: 'Promociones', component: PagePromociones, exact: true },
	{ path: '/sobre-esta-pagina', name: 'SobrePagina', component: SobrePagina, exact: true },
	{ path: '/seleccion-de-productos', name: 'Step2', component: Step2, exact: true },
	{ path: '/comparacion-de-precios', name: 'Step3', component: Step3, exact: true },
	{
		path: '/:ids/:provincia/:localidad/:categoriasSeleccionadas/:marcas',
		name: 'Shared',
		component: Step3,
		exact: true
	},
	{
		path: '/:provincia/:localidad/:supermercado?/:sucursal?',
		name: 'Argentina',
		component: FromArgentina,
		exact: true
	},
	{ path: '/*', name: 'NotFound', component: NotFound, exact: true }
];

export default routes;
