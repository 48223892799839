import React, { Component } from 'react';
import ReactGA from 'react-ga';
import AppRouter from './components/AppRouter';

export default class App extends Component {
	constructor(props) {
		super(props);
		ReactGA.initialize('UA-129166548-1');
	}

	render() {
		return <AppRouter />;
	}
}
