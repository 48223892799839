import { createBrowserHistory } from 'history';

const history = createBrowserHistory({
	basename: process.env.PUBLIC_URL
});

history.listen((location, action) => {
	if (location.pathname === '/') {
	} else if (location.pathname === '/seleccion-de-productos') {
	} else if (location.pathname === '/comparacion-de-precios') {
	}
});

export default history;
