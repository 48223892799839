import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import routes from '../utils/routes';
import history from '../utils/history';

class AppRouter extends Component {
	render() {
		return (
			<Router history={history}>
				<div>
					<Switch>
						{routes.map((route) => {
							return route.component ? (
								<Route
									key={route.name}
									path={route.path}
									exact={route.exact}
									name={route.name}
									render={(props) => <route.component {...props} />}
									history={route.history}
								/>
							) : null;
						})}
						<Redirect to="/" />
					</Switch>
				</div>
			</Router>
		);
	}
}

export default AppRouter;
